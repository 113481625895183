
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputSwitch from "primevue/inputswitch";

export default defineComponent({
  components: {
    DataTable,
    Column,
    InputSwitch,
  },
  methods: {
    ...mapActions({
      fetchAccountActivity: "generalLedger/fetchAccountActivity",
    }),
    getAccountActivity(account: string, includeUnposted: boolean) {
      this.loading = true;
      this.fetchAccountActivity({
        account: account,
        includeUnposted: includeUnposted ? "Y" : "",
      })
        .then((data: any) => {
          this.transactions = data.results;
          (this.$refs.accountActivity as any).scrollIntoView({
            behavior: "smooth",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    tableTitle(): string {
      return this.account.account
        ? "Account Activity for " +
            this.account.account +
            " - " +
            this.account.description
        : "No Account Selected";
    },
  },
  data() {
    return {
      transactions: [],
      includeUnposted: false,
      loading: false,
    };
  },
  props: {
    account: {
      type: Object,
      required: true,
      default: () => ({ account: "", description: "" }),
    },
  },
  watch: {
    account: function (newVal, oldVal) {
      this.getAccountActivity(newVal.account, this.includeUnposted);
    },
    includeUnposted: function (newVal, oldVal) {
      this.getAccountActivity(this.account.account, newVal);
    },
  },
});
