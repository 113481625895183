
import Card from "primevue/card";
import Button from "primevue/button";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import { mapActions, mapGetters } from "vuex";
import { defineComponent } from "vue";
import AccountsGLTable from "./AccountsGLTable.vue";
import AccountActivity from "./AccountActivity.vue";
import {
  TrialBalance,
  GeneralLedgerLineItem,
} from "@/types/state/generalLedger";

export default defineComponent({
  components: {
    Accordion,
    AccordionTab,
    Card,
    AccountsGLTable,
    AccountActivity,
    Button,
  },
  async created() {
    this.trialBalance = await this.fetchTrialBalance();
  },
  methods: {
    ...mapActions({
      fetchTrialBalance: "generalLedger/fetchTrialBalance",
      addOpenedAccountingTab: "accountingInquiry/addOpenedAccountingTab",
      addGLLineItem: "accountingInquiry/addGLLineItem",
    }),
    handleTrialBalanceRowClick(event: any) {
      this.account = {
        account: event.account,
        description: event.accountDescription,
      };
    },
    handleNewEntry() {
      const count =
        this.getNewGLCount === undefined ? 1 : this.getNewGLCount + 1;
      const itemToEdit = {
        record: { transaction_id: `New GL-${count}`, attachments_items: [] },
        activeInnerTab: 0,
        accountType: "gl",
        old_record: {},
      };
      this.addOpenedAccountingTab(itemToEdit);
      const item = {
        account: "",
        accountDescription: "",
        debit: "0.00",
        credit: "0.00",
        inactive: false,
        notes: "",
        li: 1,
      } as unknown as GeneralLedgerLineItem;
      this.addGLLineItem({ accountId: `New GL-${count}`, item: item });
      this.$router.push(`/accounting/gl/${itemToEdit.record.transaction_id}`);
    },
  },
  data() {
    return {
      account: { account: "", description: "" },
      trialBalance: {} as TrialBalance,
    };
  },
  computed: {
    ...mapGetters({
      getNewGLCount: "accountingInquiry/getNewGLCount",
    }),
  },
});
