
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import SpeedDial from "primevue/speeddial";
import { defineComponent } from "vue";
import Utils from "@/utility/utils";

export default defineComponent({
  components: {
    DataTable,
    Column,
    // SpeedDial,
  },
  emits: ["account-row-click"],
  props: {
    tableData: {
      type: Array,
      required: true,
    },
  },
  methods: {
    formatedAmount(amt: string) {
      if (!amt || parseInt(amt) === 0) {
        return "";
      } else {
        return Utils.formatPrice(amt);
      }
    },
    getSpeedDialActions(data: any) {
      return [
        {
          label: "Config",
          icon: "pi pi-cog",
          command: () => {
            console.log("config", data);
          },
        },
        {
          label: "Edit",
          icon: "pi pi-pencil",
          command: () => {
            console.log("edit", data);
          },
        },
        {
          label: "Send",
          icon: "pi pi-envelope",
          command: () => {
            console.log("send", data);
          },
        },
        {
          label: "Print",
          icon: "pi pi-print",
          command: () => {
            console.log("print", data);
          },
        },
      ];
    },
  },
});
